import React from 'react'

// Constants
import { THEMECONTENT } from '@constants'

// Elements
import { Title, List } from '@theme'
import { Layout } from '@layouts'
import { Artical } from '@sections'
import { BtnLink, BtnLinkWrapper, Form } from '@components'

const Services = (props) => (
	<Layout props={props}>
		<Artical
			title="Pumptec Services"
			par={`Pumptec offers a wide range of services from design to repairs and maintenance.`}
		>
			<Title
				as="h5"
				clr="secondary"
				min={16}
				max={24}
				marginTop="4vh"
				marginBot="0.7em"
			>
				Pumptec offers a complete service including:
			</Title>
			<List clr={THEMECONTENT.white.par} min={14} max={20}>
				<li>Pump and meter selection.</li>
				<li>System design and flow calculations.</li>
				<li>Assistance with commissioning.</li>
				<li>Preventative maintenance.</li>
				<li>Failure analysis.</li>
				<li>
					Repairs on all equipment supplied by us, either in our own workshop or on
					site.
				</li>
			</List>
		</Artical>

		<Artical
			theme="primary"
			title="Get in touch"
			subTitle="SERVICE FORM"
			par={`To find out more on services provided, Fill in the contact form and we will get back to you.`}
		>
			<Form formId="service_form" formSubject="Website service form enquiry" />
		</Artical>
		<Artical
			par={`
					View the vast range of quality imported quality products from all corners of the globe.
				`}
		>
			<BtnLinkWrapper marginTop="6vh">
				<BtnLink title="Supplier and Products" linkTo="/products" />
			</BtnLinkWrapper>
		</Artical>
	</Layout>
)

export default Services
